
import Vue from "vue";
import Component from "vue-class-component";
import { Auth } from "@/store/modules"

@Component({ name: "LoginCallback" })
export default class extends Vue {
  errorMessage: string = '';

  async mounted () {
    try {
      const correctQuery = (this.$route.query as any).code && (this.$route.query as any).state;

      if (!correctQuery) {
        throw new Error('Missing authorization params.');
      }

      const data = { ...this.$route.query };
      this.$router.push({ query: {} });
      await Auth.oauthGetToken(data as any);
    } catch (err) {
      const responseError = err.response && err.response.data && err.response.data.message;
      if (responseError) {
        console.warn(responseError);

        if (responseError === 'WRONG_AUTH_PROVIDER') {
          this.errorMessage = 'It appears that there is another sign in method associated with your account. Please try logging in again using different log in provider (Google or Facebook)';
          return;
        }
      }
      console.warn(err.message);
      this.errorMessage = 'There was an unexpected error while trying to authenticate your account. Please try again or contact us.';
    }
  }

  logout() {
    Auth.openLogoutPage();
  }
}
